<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Roles</h3>
                <b-button @click="$bvModal.show('add-role-modal')"
                    class="export equipment d-flex justify-content-center align-items-center w-fit-content"
                    variant="primary">
                    <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white p-0">Add Role</p>
                </b-button>
            </div>
            <b-table responsive :fields="fields" :items="items" hover :per-page="perPage" :current-page="currentPage"
                show-empty>
                <template #cell(action)="data">
                    <div v-if="data.item.name !== 'Admin'">
                        <svg @click="roleID = data.item.id" class="mr-4 cursor-pointer" width="21" height="21"
                            viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg @click="showRoleDeletePopup(data.item.id)" class="cursor-pointer" width="19" height="20"
                            viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                    <span v-else>-</span>
                </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal id="add-role-modal" centered no-close-on-esc no-close-on-backdrop
            :footer-class="'d-block px-3 pt-0 pb-3 m-0'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Add Role
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row align-v="center">
                    <b-col md="12">
                        <b-form-group label="Role:" label-for="check-user" class="text-left label text-capitalize">
                            <b-form-input type="text" placeholder="Enter Role Name" class="input" required
                                v-model="role"></b-form-input>
                            <p v-if="errors &&
                                errors.role
                                " class="field-error">
                                {{ errors.role[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0 mx-2">
                    <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetPopup" variant="secondary">Cancel</b-button>
                    <div class="d-flex align-items m-0">
                        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="addRole">Save</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <DeleteModal v-if="showDeletePopup" @confirm="deleteItem($event)" />
        <UpdatePermissions :roleID="roleID" v-if="!showDeletePopup" @close="roleID = null" />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import UpdatePermissions from './UpdatePermissions.vue';
import DeleteModal from "@/components/DeleteModal.vue";
export default {
    name: "Roles",
    components: { UpdatePermissions, DeleteModal },
    data() {
        return {
            roleID: null,
            fields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            perPage: 50,
            currentPage: 1,
            role: "",
            errors: {},
            showDeletePopup:false,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
        }
    },
    computed:{
        rows() {
            return this.items.length;
        },
        positionText() {
            let endIndex = this.currentPage * this.perPage,
                startIndex =
                    this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

            return (
                startIndex +
                " to " +
                (endIndex > this.rows ? this.rows : endIndex) +
                " of " +
                this.rows
            );
        }
    },
    methods: {
        ...mapActions(['getUserRoles', "addUserRole", 'setLoader', 'setNotification','deleteUserRole']),
        async deleteItem(status) {
            if (status) {
                this.setLoader(true)
                try {
                    await this.deleteUserRole(`${this.roleID}/?company_id=${this.userCompany}`)
                    await this.setNotification({
                        msg: "Role successfully deleted!!",
                        type: "success",
                        color: "green",
                    });
                    this.roleID = null
                    this.showDeletePopup = false;
                    await this.getRoles()
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            }
            else this.showDeletePopup = false
        },
        showRoleDeletePopup(id) {
            this.showDeletePopup = true;
            this.roleID = id;
        },
        resetPopup() {
            this.$bvModal.hide('add-role-modal');
            this.role = ''
            this.errors = {}
        },
        async getRoles() {
            const data = await this.getUserRoles()
            this.items = []
            data.map((item) => {
                this.items.push({
                    name: item.name,
                    id: item.id
                })
            })
        },
        async addRole() {
            try {
                const nullKeys = this.checkNullValues({
                    name: this.role
                })
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    const data = await this.addUserRole({
                        name: this.role,
                        permissions: []
                    })
                    this.resetPopup()
                    await this.setNotification({
                        msg: `Role Added Successfully`,
                        type: "success",
                        color: "green",
                    });
                    this.roleID = data.id
                    this.getRoles()
                    this.setLoader(false)
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false);
                if (error && error.error && Array.isArray(error.error)) {
                    await this.setNotification({
                        msg: error.error[0],
                        type: "error",
                        color: "red",
                    });
                }
                if (typeof error === "object") {
                    this.errors = error;
                }
            }

        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
    },
    mounted() {
        this.getRoles()
    },
}
</script>