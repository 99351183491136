<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="roleDetails && roleDetails.name" backdrop shadow
            :visible="!!roleID" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3 overflow-x-hidden" v-if="roleDetails">
                <div v-for="(permissions, key) in filteredPermissions" :key="key" class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left mb-0 pl-0">{{ key }}</h3>
                    <b-row
                        :class="key === Object.keys(filteredPermissions).slice(-1)[0] && index === roleDetails.sections[key] ? 'mb-7rem' : index > 1 ? 'mt-3' : ''"
                        v-for="index in roleDetails.sections[key]" :key="index">
                        <b-col md='6' sm="12" class="text-left mt-3"
                            v-for="(item, index) in permissions.filter((item) => item.section === index)" :key="index">
                            <label class="text-left w-fit-content">{{ item.label }}</label>
                            <b-form-checkbox v-model="item.allow" name="check-button" switch size="lg"
                                class="ml-0-6rem"></b-form-checkbox>
                        </b-col>
                    </b-row>
                    <hr class="my-4" v-if="key !== Object.keys(filteredPermissions).slice(-1)[0]" />
                </div>
            </div>
            <div class="sidebar-footer position-fixed">
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Close</b-button>
                    <b-button class="mx-0 h-40px w-100px" @click="onUpdateRole()" variant="primary">Save</b-button>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "UpdatePermissions",
    props: ['roleID'],
    data() {
        return {
            roleDetails: null,
        }
    },
    computed: {
        filteredPermissions() {
            return Object.fromEntries(
                Object.entries(this.roleDetails.permissions).filter(([key]) => key !== 'Admin')
            );
        }
    },
    watch: {
        async roleID() {
            if (this.roleID) {
                this.setLoader(true)
                try {
                    this.roleDetails = await this.getRoleDetails(this.roleID)
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                }
            }
        }
    },
    methods: {
        ...mapActions(['getRoleDetails', 'setLoader', 'updateUserRole', 'setNotification', 'getRolePermissions']),
        resetPopup() {
            this.roleDetails = null
            this.$emit('close');
        },
        getChangedPermissions() {
            const permssions = [];
            for (const key in this.filteredPermissions) {
                this.filteredPermissions[key].forEach((item) => {
                    console.log(item)
                    if (item.allow) {
                        permssions.push(item.permission_id);
                    }
                });
            }
            return permssions;
        },
        async onUpdateRole() {
            const permissions = this.getChangedPermissions()
            if (permissions.length) {
                this.setLoader(true)
                try {
                    await this.updateUserRole({
                        id: this.roleDetails.id,
                        payload: {
                            name: this.roleDetails.name,
                            permissions
                        }
                    })
                    await this.setNotification({
                        msg: `Successfully updated roles for ${this.roleDetails.name}`,
                        type: "success",
                        color: "green",
                    });
                    await this.getRolePermissions()
                    this.resetPopup()
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                }
            }
        }
    },
}
</script>
