<template>
    <div>
        <b-form @submit.prevent="getCompanyDetailsfromUsdot">
            <b-form-group label="USDOT #:" label-for="check-user" class="text-left label text-capitalize">
                <b-form-input type="text" placeholder="Enter USDOT #" class="input h-50px" required
                    v-model="company.usdot"></b-form-input>
            </b-form-group>
            <b-button type="submit" class="w-100 mt-3 login h-50px" variant="primary">Next</b-button>
        </b-form>
    </div>
</template>
<script>
export default {
    name: "Step3",
    props:['company','getCompanyDetailsfromUsdot']
}
</script>