<template>
    <b-modal id="modal-document" centered hide-footer no-close-on-backdrop no-close-on-esc size="lg"
        :body-class="'px-3 pt-2 overflow-preview-auto'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
        :header-class="'align-items-center'" scrollable>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                Document
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
            <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0" v-if="fileName">
                <template slot="button-content">
                    <b-icon class="dots" icon="three-dots"></b-icon>
                </template>
                <b-dropdown-item-button @click="removeFile()">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                </b-dropdown-item-button>
            </b-dropdown>
        </template>
        <div class="d-block text-center">
            <b-row>
                <b-col lg="4" md="8" sm="12">
                    <div class="text-left mb-3">
                        <label class="state">Name:</label>
                        <b-form-input v-model="documentData.name" disabled />
                        <p v-if="errors && errors.name" class="field-error">
                            {{ errors.name[0] }}
                        </p>
                    </div>
                    <div class="text-left mb-3">
                        <label for="modal-datepicker" class="state">Date:</label>
                        <b-form-datepicker id="modal-datepicker" class="mb-2" v-model="documentData.date"
                            reset-button></b-form-datepicker>
                        <p v-if="errors && errors.date" class="field-error">
                            {{ errors.date[0] }}
                        </p>
                    </div>

                    <div class="text-left mb-3">
                        <label for="modal-expiry-date" class="state">Expiry Date:</label>
                        <b-form-datepicker id="modal-expiry-date" class="mb-2" v-model="documentData.expiry_date"
                            reset-button></b-form-datepicker>
                    </div>
                    <div class="text-left mb-3">
                        <label class="state">Type:</label>
                        <b-form-select class="f-12" :options="getDotOptions" v-model="documentData.doc_type"
                            @change="setDocDescription($event)">
                        </b-form-select>
                        <p v-if="errors && errors.doc_type" class="field-error">
                            {{ errors.doc_type[0] }}
                        </p>
                    </div>
                    <div class="text-left mb-3">
                        <label class="state">Description:</label>
                        <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                            v-model="documentData.description"></b-form-textarea>
                        <p v-if="errors && errors.description" class="field-error">
                            {{ errors.description[0] }}
                        </p>
                    </div>
                    <div class="text-left mb-3">
                        <label class="state">Driver:</label>
                        <b-form-checkbox class="ml-2" v-model="documentData.driver" name="driver-button" switch size="lg" />
                    </div>
                </b-col>
                <b-col lg="8" sm="12">
                    <div class="upload-btn-wrapper" v-if="!fileName">
                        <b-img :src="require('@/assets/drop.svg')"></b-img>
                        <button v-if="!fileName" class="foo">
                            Drop your document here, or browse
                        </button>
                        <button v-else class="foo">{{ fileName }}</button>

                        <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                            accept=".pdf, .png, .jpg, .jpeg" />
                        <p v-if="errors && errors.document" class="field-error">
                            {{ errors.document[0] }}
                        </p>
                    </div>
                    <div v-else>
              <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                <div v-else>
                  <div id="panzoom-element" ref="panzoomElement">
                    <img :src="media" class="w-100 h-100 img-section" />
                  </div>
                  <div class="d-flex align-items-center justify-content-center zoom-section">
                    <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                    <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out" style="fill:white;cursor: pointer;"></b-icon>
                  </div>
                </div>
              </div>
            </div>
                </b-col>
            </b-row>
        </div>
        <div class="text-right modal-footer border-0 p-0">
            <b-button class="mt-3 mr-3 btn cancel" block @click="resetPopup">Cancel</b-button>
            <b-button class="mt-3 btn save mx-0" variant="primary" @click="sendDocumentData">Save</b-button>
        </div>
    </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import * as moment from "moment-timezone";
import Panzoom from '@panzoom/panzoom'

export default {
    name: "AddDoc",
    props: ["docData"],
    data() {
        return {
            fileName: null,
            panzoom:null,
            documentData: {
                name: null,
                object_id: null,
                date: new Date(),
                type: null,
                document: null,
                description: null,
                expiry_date: "",
                driver: true,
                public: false,
                doc_type: ""
            },
            errors: {}
        }
    },
    computed: {
        ...mapGetters(["docTypeItems", 'userCompany']),
        getDotOptions() {
            if (this.docData) return [...[{
                value: "",
                text: "",
            }], ...this.docTypeItems?.filter((item) => item.model === this.docData.type || item.model === 'All').map((item) => {
                return {
                    value: item.id,
                    text: item.type,
                }
            })]

            return []
        },
    },
    watch: {
        docData() {
            if (this.docData) {
                if (this.docData) {
                    this.documentData.name = this.docData.name
                    this.documentData.doc_type = this.docData.missing_doc_type_ids[0]
                    this.documentData.description = this.docTypeItems?.filter((item) => item.id === this.documentData.doc_type)[0].type
                }
            }
        }
    },
    methods: {
        ...mapActions(['setLoader', 'setNotification', 'sendCompanyDocuments']),
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        removeFile() {
            this.fileName = null;
            // this.$refs.docFile.value = "";
            this.documentData.document = null
            this.media = null
            this.mediaType = null
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        setDocDescription(event) {
            if (event) {
                this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
            }
            else this.documentData.description = ""
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
            this.media = URL.createObjectURL(this.documentData.document);
            if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        resetPopup() {
            this.fileName = null,
                this.documentData = {
                    name: null,
                    object_id: null,
                    date: new Date(),
                    type: null,
                    document: null,
                    description: null,
                    expiry_date: "",
                    driver: true,
                    public: false,
                    doc_type: ""
                }
                this.panzoom = null
            this.errors = {}
            this.$bvModal.hide('modal-document')
            this.$emit('close')
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    name: this.documentData.name,
                    date: this.documentData.date,
                    document: this.documentData.document,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    let formData = new FormData();
                    formData.append(
                        "date",
                        moment(this.documentData.date).format("YYYY-MM-DD")
                    );
                    formData.append("object_id", this.docData.id);
                    formData.append("description", this.documentData.description);
                    formData.append("doc_type", this.documentData.doc_type);
                    formData.append("type", this.documentData.type);
                    formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
                    formData.append(
                        "document",
                        new File(
                            [this.documentData.document],
                            this.documentData.name +
                            this.documentData.description +
                            "_" + this.userCompany + "_" +
                            new Date().getTime() +
                            "." +
                            this.documentData.document["type"].split("/").pop(),
                            { type: this.documentData.document["type"] }
                        )
                    );
                    formData.append("driver", this.documentData.driver);
                    formData.append("company_id", this.userCompany);
                    formData.append(
                        "model_type",
                        this.docData.type === 'Driver' ? 'teammember' : 'equipment'
                    )
                    await this.sendCompanyDocuments(formData);
                    await this.setNotification({
                        msg: "Document Successfully added!!",
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.resetPopup()
                    this.$emit("onAddDoc")
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                console.log(error)
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        }
    }
}
</script>