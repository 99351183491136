<template>
    <b-modal id="support-modal" centered no-close-on-backdrop scrollable :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
        :body-class="'equipment-edit-modal'" no-close-on-esc>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                Support
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="body mt-3">
            <div class="row" v-if="user">
                <b-col sm="12">
                    <b-form-group label="Name:" class="text-left mb-3">
                        <b-form-input type="text" placeholder="Enter Name" disabled class="unit"
                            v-model="name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Email:" class="text-left mb-3">
                        <b-form-input type="email" placeholder="Enter Email" class="unit" v-model="user.email"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Message:" class="text-left mb-3 ">
                        <b-form-textarea rows="5" class="resize-none" v-model="message"></b-form-textarea>
                        <p v-if="errors && errors.message" class="field-error mt-2">
                            {{ errors.message[0] }}
                        </p>
                    </b-form-group>
                </b-col>
            </div>
        </div>
        <template #modal-footer>
            <div class="text-right modal-footer border-0 p-0">
                <b-button class="mt-3 mr-3 btn cancel mb-0 m-0" variant="secondary" v-if="!expiry"
                    @click="resetForm">Cancel</b-button>
                <b-button class="mt-3 btn save mx-0 mb-0 m-0" variant="primary" @click="handleFormSubmit">Send</b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "Support",
    props: {
        expiry: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            message: "",
            errors: {}
        }
    },
    computed: {
        ...mapGetters(["user"]),
        name() {
            return this.user.first_name + " " + this.user.last_name
        }
    },
    methods: {
        ...mapActions(['contactUs', 'setNotification', 'logoutUser']),
        resetForm() {
            this.message = ''
            this.errors = {}
            this.$emit("close")
            this.$bvModal.hide('support-modal')
        },
        logout() {
            this.logoutUser();
            this.$intercom.shutdown()
            this.$router.push("/login");
        },
        async handleFormSubmit() {
            if (this.message) {
                try {
                    await this.contactUs({
                        name: this.name,
                        email: this.user.email,
                        message: this.message
                    })
                    await this.setNotification({
                        msg: `Message sent Successfully`,
                        type: "success",
                        color: "green",
                    });
                    this.errors = {}
                    if(this.expiry) this.logout()
                    this.resetForm()
                }
                catch (e) {
                    console.log(e)
                }
            }
            else this.errors = {
                message: ['THIS FIELD IS REQUIRED.']
            }
            console.log(this.errors)
        }
    },
    mounted(){
        console.log(this.expiry,'23')
    }
}
</script>