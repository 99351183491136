<template>
  <b-form @submit.prevent="onSignUp">
    <b-row>
      <b-col sm="12">
        <b-form-group label="First Name:" class="text-left label">
          <b-form-input type="text" placeholder="Enter First Name" class="input h-50px" required
            v-model="user.first_name"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group label="Last Name:" class="text-left label">
          <b-form-input type="text" placeholder="Enter Last Name" class="input h-50px" required
            v-model="user.last_name"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group label="Email:" label-for="input-1" class="text-left label text-capitalize">
      <b-form-input type="email" class="mb-3 input h-50px" placeholder="Enter Email" required
        v-model="user.email" disabled></b-form-input>
    </b-form-group>

    <b-form-group label="Password:" class="text-left label password password-2">
      <b-form-input class="input h-50px" placeholder="Enter Password" required :type="showPassword1 ? 'text' : 'password'"
        v-model="user.password1"></b-form-input>
      <b-icon :icon="showPassword1 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword1 = !showPassword1"></b-icon>
    </b-form-group>

    <b-form-group label="Confirm Password:" class="text-left label password password-2">
      <b-form-input class="input h-50px" placeholder="Enter Confirm Password" required
        :type="showPassword2 ? 'text' : 'password'" v-model="user.password2"></b-form-input>
      <b-icon :icon="showPassword2 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword2 = !showPassword2"></b-icon>
    </b-form-group>
    <b-button type="submit" class="w-100 mt-3 login h-50px" variant="primary">Next</b-button>
  </b-form>
</template>
<script>
export default {
  data() {
    return {
      showPassword1: false,
      showPassword2: false
    }
  },
  name: "Step2",
  props: ['onSignUp', 'user']
}
</script>