<template>
    <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 maintenance-page">
        <b-row>
            <b-col cols="" class="">
                <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
                    <h2 class="text-left">Safety</h2>
                    <div class="d-flex justify-content-between justify-content-sm-center flex-wrap" v-if="allowAddRecord">
                        <b-button class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content"
                            variant="primary" @click="showSafetyModal()">
                            <b-icon icon="plus" aria-hidden="true"></b-icon>
                            <p class="mb-0 ml-2 text-white">Add {{ activeTab === 'dotInspections' ? 'Inspection' :  activeTab === 'accidents' ? 'Accident': 'Claim' }}</p>
                        </b-button>
                    </div>
                </div>
                <div class="data-table team-data-table bg-white rounded table-custom-border">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0">
                            <v-tab @click="activeTab = 'dotInspections'">DOT Inspections</v-tab>
                            <v-tab @click="activeTab = 'accidents'">Accidents</v-tab>
                            <v-tab @click="activeTab = 'claims'">Claims</v-tab>
                        </v-tabs>
                    </div>
                    <div class="bg-white pt-3 pt-sm-4">
                        <div class="table_row">
                            <DotInspections v-if="activeTab === 'dotInspections'" :allowAddInspection="allowAddInspection" @close="allowAddInspection = false"/>
                            <Accidents v-else-if="activeTab === 'accidents'" :allowAddAccident="allowAddAccident" @close="allowAddAccident = false"/>
                            <Claims v-else :allowAddClaim="allowAddClaim" @close="allowAddClaim = false"/>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import DotInspections from '@/components/Safety/DotInspections.vue';
import Accidents from '@/components/Safety/Accidents.vue';
import Claims from '../../components/Safety/Claims.vue';
import { mapGetters } from 'vuex';
export default {
    name: "Safety",
    components: { DotInspections,Accidents,Claims },
    data() {
        return {
            activeTab: "dotInspections",
            allowAddInspection:false,
            allowAddAccident: false,
            allowAddClaim: false,
        }
    },
    methods: {
        showSafetyModal(){
            if (this.activeTab === 'dotInspections') this.allowAddInspection = true
            else if (this.activeTab === 'accidents') this.allowAddAccident = true
            else this.allowAddClaim = true
        },
    },
    computed:{
        ...mapGetters(['rolePermissions']),
        allowAddRecord() {
            return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][2].allow
        },
    }
}
</script>