<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3">
    <b-row>
      <b-col cols="" class="">
        <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
          <h2 class="text-left">Expiry Data</h2>
          <b-button class="export d-flex justify-content-center align-items-center" @click="downloadExcel"
            variant="success">
            <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
            <p class="mb-0 ml-3 text-white">Export CSV</p>
          </b-button>
        </div>
        <div class="data-table bg-white pt-3 pt-sm-4 table-custom-border rounded-top">
          <div class="equipment-border filter-box table_row">
            <div class="d-flex px-3 px-sm-4 mb-4">
              <b-form-group class="text-left label font-weight-normal search-field mb-0">
                <b-input-group class="username-input-field">
                  <b-form-input type="text" placeholder="Search" v-model="searchField" class="unit f-12"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary">
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                          stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <div class="ml-auto">
                      <b-dropdown id="dropdown-divider" ref="filterDropdown"
                        class="filter-section table-columns-dropdown mt-0"
                        :class="checkFilterStatus ? 'active-dropdown' : ''">
                        <template slot="button-content">
                          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                              stroke-width="0.2" />
                          </svg>

                        </template>
                        <div class="filter-section-items">
                          <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                         
              <b-form-group label="Type" class="text-left select_arrow label mb-0">
                <b-form-select type="text" class="mb-4 input f-12" required v-model="searchType"
                  :options="typeOptions"></b-form-select>
              </b-form-group>

              <b-form-group label="Days:" class="text-left label mb-0">
                <b-form-select class="mb-4 input f-12" :options="[
                  {
                    value: null,
                    text: '',
                  },
                  {
                    value: '15',
                    text: '0-15',
                  },
                  {
                    value: '31',
                    text: '0-31',
                  },
                ]" v-model="searchDays">
                </b-form-select>
              </b-form-group>
                        </div>
                        <div class="d-flex align-items-center justify-content-between buttons-section">
                          <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                          <b-button class="text-dark btn-secondary-2"
                            @click="$refs.filterDropdown.hide()">Close</b-button>
                        </div>
                      </b-dropdown>
                      </div>
            </div>

            <b-table responsive :fields="fields" :items="searchItems" hover :per-page="perPage"
              :current-page="currentPage" class="mt-2"
              :show-empty="emptyTable"
              >
              <template #cell(name)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <p v-if="$store.state.user.user.team_member.role === 'Admin' ||
                  $store.state.user.user.team_member.role === 'Operations'
                  " class="text_primary cursor-pointer mb-0"
                  @click="editDocument(data.item.id)">{{ data.value }}</p>
                <p v-else class="mb-0">{{ data.value }}</p>
              </template>
              <template #cell(date)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(days)="data">
                <span :style="data.item.color">{{ data.value }} Days</span>
              </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
              <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                  <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <div class="pagination">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table"></b-pagination>
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-3 mt-sm-0">{{ positionText }}</p>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modal-edit-document" centered hide-footer no-close-on-backdrop :body-class="'p-4'"
    no-close-on-esc
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Edit Document
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label class="state">Name</label>
              <v-select class="bg_white w-100" v-model="documentData.name" :options="nameOptions" item-value="id"
                item-text="label" :reduce="(option) => option.id" @option:selected="getNameItem($event)"
                @search="searchAssets">
                <span slot="no-options">
                  Search Unit No or Name...
                </span>
              </v-select>
              <p v-if="errors && errors.name" class="field-error">
                {{ errors.name[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date</label>
              <b-form-datepicker id="modal-datepicker" class="mb-2" v-model="documentData.date"
                reset-button></b-form-datepicker>
              <p v-if="errors && errors.date" class="field-error">
                {{ errors.date[0] }}
              </p>
            </div>

            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date</label>
              <b-form-datepicker id="modal-expiry-date" class="mb-2" v-model="documentData.expiry_date"
                reset-button></b-form-datepicker>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description</label>
              <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                v-model="documentData.description"></b-form-textarea>
              <p v-if="errors && errors.description" class="field-error">
                {{ errors.description[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Driver</label>
              <b-form-checkbox class="ml-2" v-model="documentData.driver" name="driver-button" switch size="lg" />
            </div>
            <div class="text-left" v-if="showPublicQR">
              <label class="state">Public:</label>
              <b-form-checkbox class="ml-2" v-model="documentData.public" name="public-button" switch size="lg" />
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="upload-btn-wrapper">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <p v-if="fileName" class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                @click="removeFile()">
                Delete
              </p>
              <input ref="file" type="file" name="myfile" v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mt-3 mr-3 btn cancel" block @click="$bvModal.hide('modal-edit-document')">Cancel</b-button>
        <b-button class="mt-3 btn save mx-0" variant="primary" @click="sendDocumentData">Save</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import router from "../../router";
import * as moment from "moment-timezone";
export default {
  name: "expiry-data",
  components: {
    vSelect
  },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      widthBox: 220,
      expiryDispatchGroups: [],
      showPublicQR: false,
      nameOptions:[],
      fileName: "",
      emptyTable:false,
      items: [],
      states:[],
      documentData: {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
      },
      errors: {
        date: null,
        name: null,
        document: null,
        description: null,
      },
      typeOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
        {
          value: "Driver",
          text: "Driver",
        },
        {
          value: "Company",
          text: "Company",
        },
      ],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      searchField: null,
      searchType: null,
      searchDays: null,
      searchGroups: null,
      fields: [
        {
          key: "Name",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Type",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Description",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          label:"Expiry Date",
          key: "Date",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Days",
          sortable: true,
          thClass:'font-table-weight-bold'
          // Variant applies to the whole column, including the header and footer
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "documents",
      "allEquipments",
      "allTeamMembers",
      "dispatchGroups",
      "allCountries",
      "company",
      "singleDoc",
      "userCompany"
    ]),
    checkFilterStatus() {
      return this.searchField || this.searchType || this.searchDays || this.searchGroups
    },
    rows() {
      return this.searchItems.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows
      );
    },
    searchItems() {
      const searchKeysWithFields = {
        Type: this.searchType,
        SearchField: this.searchField,
        Days: this.searchDays,
        Groups: this.searchGroups,
      };
      let filteredItems = this.items;
      for (let [field, searchQuery] of Object.entries(searchKeysWithFields)) {
        if (field === "Groups" && this.searchGroups?.length > 0) {
          filteredItems = filteredItems.filter(
            (x) =>
              (x.dispatch_group1 !== null || x.dispatch_group2 !== null) &&
              (this.searchGroups.includes(x.dispatch_group1) ||
                this.searchGroups.includes(x.dispatch_group2))
          );
        } else if (field === "Days" && this.searchDays) {
          const currentDate = moment();
          const lastDate = moment().add(parseInt(this.searchDays), "days");
          filteredItems = filteredItems.filter((x) => {
            const date = new Date(x.Date);
            return date >= new Date(currentDate) && date <= new Date(lastDate);
          });
        }
        else if (field === 'SearchField' && this.searchField) {
          filteredItems = filteredItems.filter((item) => (
            item.Description.toLowerCase().includes(this.searchField.toLowerCase()) || item.Name.toLowerCase().includes(this.searchField.toLowerCase())
          ))
        }
        else if (searchQuery && !Array.isArray(searchQuery)) {
          filteredItems = this.searchByKeys(searchQuery, field, filteredItems);
        }
      }
      return filteredItems;
    },
  },
  methods: {
    ...mapActions([
      "getAllEquipments",
      "getAllDocuments",
      "postExcelData",
      "getAllTeamMembers",
      "setNotification",
      "getDispatchGroups",
      "getSingleDoc",
      "setLoader",
      "updateTeamMemberDocument"
    ]),
    resetFilterFields(){
      this.searchType = ""
      this.searchField = ""
      this.searchDays = ""
      this.searchGroups = ""
    },
    async sendDocumentData() {
      if (this.documentData.date && this.documentData.name) {
        try {
          this.setLoader(true);
          let formData = new FormData();
          if (this.$refs.file.files.length === 0) {
            this.documentData.document = null;
          }
          formData.append("object_id", this.documentData.name.split("-")[1]);
          formData.append(
            "date",
            moment(this.documentData.date).format("YYYY-MM-DD")
          );
          formData.append("description", this.documentData.description);
          formData.append("type", this.documentData.type);
          if (this.documentData.expiry_date)
            formData.append("expiry_date", this.documentData.expiry_date);
          if (this.documentData.document)
            formData.append(
              "document",
              new File(
                [this.documentData.document],
                this.documentData.document["name"].split(".")[0] +
                "_" + this.userCompany+"_"+
                new Date().getTime() +
                "." +
                this.documentData.document["type"].split("/").pop(),
                { type: this.documentData.document["type"] }
              )
            );
          formData.append("driver", this.documentData.driver);
          formData.append("public", this.documentData.public);
          formData.append(
            "model_type",
            this.nameOptions.filter(
              (item) => item.id === this.documentData.name
            )[0].type
          );
            this.documentData = await this.updateTeamMemberDocument({
              id: this.documentData.id,
              payload: formData,
            });
            await this.setNotification({
              msg: "Document Successfully updated!!",
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("modal-edit-document");
            await this.getDocuments();
            this.documentData = {
              object_id: null,
              date: null,
              name: null,
              type: null,
              document: null,
              description: null,
              expiry_date: "",
              driver: true,
              public: false,
            };
            this.fileName = null;
            this.setLoader(false);
          

        }
        catch (e) {
          console.log(e)
        }
      }
      else {
        this.errors.date = this.documentData.date
          ? null
          : ["This field is required."];
        this.errors.name = this.documentData.name
          ? null
          : ["This field is required."];
      }
    },
    async editDocument(id) {
      await this.getSingleDoc(id)
      this.nameOptions.push({
        id: `${this.singleDoc.type}-${this.singleDoc.type === "Company" ? this.company[0].id : this.singleDoc.document_of.id}`,
        label: this.singleDoc.document_of.unit_no
          ? this.singleDoc.document_of.unit_no
          : this.singleDoc.document_of.name,
        type: `${this.singleDoc.type === "Company" ? 'company' : this.singleDoc.type === "Vehicle" || this.singleDoc.type === "Trailer" ? 'equipment' : 'teammember'}`,
      })
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      this.showPublicQR = this.singleDoc.type === "Vehicle" || this.singleDoc.type === "Trailer";
      this.documentIndex = id;
      this.documentData = this.singleDoc
      this.documentData.name = `${this.singleDoc.type}-${this.singleDoc.type === "Company" ? this.company[0].id : this.singleDoc.document_of.id}`;
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.split("/").pop().split("#")[0].split("?")[0];
      } else {
        this.documentData.document = null;
        this.fileName = "";
        this.errors = {
          date: null,
          type: null,
          document: null,
          description: null,
        };
      }
      console.log(this.documentData)
      this.$bvModal.show("modal-edit-document");
    },
    async searchAssets(searchText) {
      if(searchText) {
      let options = []
      let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
      const equipmentRecords = await this.getAllEquipments(url);
      if (equipmentRecords.records.length === 0) {
        url = `name=${searchText}&sort_field=name&sort_order=asc&&page=1&page_size=10`
        const driverRecords = await this.getAllDrivers(url);
        if (driverRecords.records?.length) {
          driverRecords.records?.map((item) => {
            options.push({
              id: `Driver-${item.id}`,
              label: item.name,
              type: "teammember",
            });
          });
        }
      }
      else {
        equipmentRecords.records.map((item) => {
          options.push({
            id: `${item.type}-${item.id}`,
            label: item.unit_no,
            type: "equipment",
          });
        });
      }
      this.nameOptions = this.nameOptions.concat(options).concat([{
        id: `Company-${this.company[0].id}`,
        label: "Company",
        type: "company",
      }])
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
    }
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.file.files[0];
      this.fileName = this.documentData.document.name;
    },
    getNameItem(item) {
      this.showPublicQR = item.type === "equipment";
    },
    removeFile() {
      this.fileName = null;
      this.$refs.file.value = "";
    },
    searchByKeys(searchQuery, field, filteredItems) {
      if (["Days"].includes(field)) {
        return filteredItems.filter(
          (x) => x[field] !== null && x[field].toString().includes(searchQuery)
        );
      }
      return filteredItems.filter(
        (x) =>
          x[field] &&
          x[field]
            .toString()
            .toLowerCase()
            .includes(searchQuery.toString().toLowerCase())
      );
    },
    async downloadExcel() {
      await this.searchItems.forEach((item) => {
        delete item.id;
        delete item.color;
        delete item.route;
        delete item.Date
        delete item.dispatch_group1;
        delete item.dispatch_group2;
      });
      console.log(this.searchItems)
      const link = await this.postExcelData(this.searchItems);
      const fileLink = URL.createObjectURL(new Blob([link]));
      this.downloadURI(fileLink);
    },
    async getDocuments() {
      this.items = []
      await this.getAllDocuments();
      this.documents.forEach((doc) => {
        if (doc.Days <= 0) {
          var color = "color:red";
        } else if (doc.Days <= 31 && doc.Days >= 1) {
          color = "color:orange";
        } else {
          color = "color:green";
        }
        this.items.push({
          Name: doc.name,
          id: doc.id,
          Type: doc.type,
          Description: doc.description,
          Date: `<span style=${color}>${moment(doc.Date).format("MMM DD, YYYY")}</span>`,
          "Expiry Date":moment(doc.Date).format("MMM DD, YYYY"),
          color: color,
          Days: doc.Days,
          route: doc.team_id ? "driver" : "equipment",
          dispatch_group1: doc.dispatch_group1,
          dispatch_group2: doc.dispatch_group2,
        });
        if(this.items.length === 0) this.emptyTable = true
          else this.emptyTable = false
      });
      this.items = this.items.sort((a, b) => a.Days - b.Days);
      console.log(this.items)
    },
    downloadURI(uri) {
      let link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  async mounted() {
    try {
      await this.getDispatchGroups(this.company[0].id);
      await this.getDocuments()
      this.dispatchGroups.map((item) => {
        this.expiryDispatchGroups.push({
          id: item.id,
          label: item.name,
        });
      });
    } catch (error) {
      console.log(error);
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      await router.push("/");
    }
  },
};
</script>

<style>
.data-table fieldset {
  width: 100%;
  margin-right: 20px;
}

.expiry-data .btn {
  height: 40px;
}

@media (max-width: 575px) {
  .expiry-data h2 {
    margin-bottom: 10px;
  }

  .page-link {
    padding: 0rem 0.35rem !important;
    margin-right: 7px !important;
  }
}
</style>
