import {
    getReq, patchReq, postReq, deleteReq
} from "../../../services/api";

const state = {
    dotInspectionData: null,
    currentDotInspection: null,
    currentAccidentData: null,
    currentClaimData:null
}
const getters = {
    dotInspectionData: (state) => state.dotInspectionData,
    currentDotInspection: (state) => state.currentDotInspection,
    currentAccidentData: (state) => state.currentAccidentData,
    currentClaimData:(state)=>state.currentClaimData
}
const actions = {
    async getAllDotInspections(_, url) {
        try {
            const data = await getReq(`app/safety-inspection/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getAllAccidentData(_, url) {
        try {
            const data = await getReq(`app/safety-accident/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getAllClaims(_, url) {
        try {
            const data = await getReq(`app/safety-claim/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addViolation(_, { id, payload }) {
        try {
            const data = await postReq(`app/safety-inspection/${id}/violation/`, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addAccidentContact(_, { id, payload }) {
        try {
            const data = await postReq(`app/safety-accident/${id}/contact/`, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addClaimContact(_, { id, payload }) {
        try {
            const data = await postReq(`app/safety-claim/${id}/contact/`, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async updateViolation(_, { id, payload }) {
        try {
            const data = await patchReq(`app/safety-inspection/${id}/violation`, payload.id, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async updateAccidentContact(_, { id, payload }) {
        try {
            const data = await patchReq(`app/safety-accident/${id}/contact`, payload.id, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async updateClaimContact(_, { id, payload }) {
        try {
            const data = await patchReq(`app/safety-claim/${id}/contact`, payload.id, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteViolation(_, { inspectionID, violationID }) {
        try {
            await deleteReq(`app/safety-inspection/${inspectionID}/violation`, violationID);
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteAccidentContact(_, { accidentID, contactID }) {
        try {
            await deleteReq(`app/safety-accident/${accidentID}/contact`, contactID);
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteClaimContact(_, { claimID, contactID }) {
        try {
            await deleteReq(`app/safety-claim/${claimID}/contact`, contactID);
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteDotInspectionDocument(_, { inspectionID, documentID }) {
        try {
            await deleteReq(`app/safety-inspection/${inspectionID}/document`, documentID);
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteAccidentDocument(_, { accidentID, documentID }) {
        try {
            await deleteReq(`app/safety-accident/${accidentID}/document`, documentID);
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteClaimDocument(_, { claimID, documentID }) {
        try {
            await deleteReq(`app/safety-claim/${claimID}/document`, documentID);
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getAllViolations(_, { id }) {
        try {
            const {data} = await getReq(`app/safety-inspection/${id}/violation/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getAccidentContacts(_, { id }) {
        try {
            const {data} = await getReq(`app/safety-accident/${id}/contact/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getClaimContacts(_, { id }) {
        try {
            const {data} = await getReq(`app/safety-claim/${id}/contact/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getDotInspection({ commit }, id) {
        try {
            const data = await getReq(`app/safety-inspection/${id}/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            commit("setCurrentDotInspection", data)
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getAccidentData({ commit }, id) {
        try {
            const data = await getReq(`app/safety-accident/${id}/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            commit("setCurrentAccidentData", data)
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getClaimData({ commit }, id) {
        try {
            const data = await getReq(`app/safety-claim/${id}/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            commit("setCurrentClaimData", data)
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getDotInspectionDocs(_, { id }) {
        try {
            const {data} = await getReq(`app/safety-inspection/${id}/document/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getAccidentDocs(_, { id }) {
        try {
            const {data} = await getReq(`app/safety-accident/${id}/document/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async getClaimDocs(_, { id }) {
        try {
            const {data} = await getReq(`app/safety-claim/${id}/document/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addDotInspectionDoc(_, { id, payload }) {
        try {
            const data = await postReq(`app/safety-inspection/${id}/document/`, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async updateDotInspectionDoc(_, { id, docID, payload }) {
        try {
            const data = await patchReq(`app/safety-inspection/${id}/document`, docID, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addAccidentDoc(_, { id, payload }) {
        try {
            const data = await postReq(`app/safety-accident/${id}/document/`, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async updateAccidentDoc(_, { id, docID, payload }) {
        try {
            const data = await patchReq(`app/safety-accident/${id}/document`, docID, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addClaimDoc(_, { id, payload }) {
        try {
            const data = await postReq(`app/safety-claim/${id}/document/`, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async updateClaimDoc(_, { id, docID, payload }) {
        try {
            const data = await patchReq(`app/safety-claim/${id}/document`, docID, payload);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async addDotInspection(_, payload) {
        try {
            const { id } = await postReq(`app/safety-inspection/`, payload);
            return id
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            throw error.response.data;
        }
    },
    async addAccident(_, payload) {
        try {
            const { id } = await postReq(`app/safety-accident/`, payload);
            return id
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            throw error.response.data;
        }
    },
    async addClaim(_, payload) {
        try {
            const { id } = await postReq(`app/safety-claim/`, payload);
            return id
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            throw error.response.data;
        }
    },
    async updateDotInspection(_, { id, payload }) {
        try {
            const data = await patchReq(`app/safety-inspection`, id, payload);
            return data;
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            throw error.response.data;
        }
    },
    async updateAccident(_, { id, payload }) {
        try {
            const data = await patchReq(`app/safety-accident`, id, payload);
            return data;
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            throw error.response.data;
        }
    },
    async updateClaim(_, { id, payload }) {
        try {
            const data = await patchReq(`app/safety-claim`, id, payload);
            return data;
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            throw error.response.data;
        }
    },
    async deleteDotInspection(_, { inspectionID }) {
        try {
            const data = await deleteReq(`app/safety-inspection`, inspectionID);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteAccident(_, { accidentID }) {
        try {
            const data = await deleteReq(`app/safety-accident`, accidentID);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    },
    async deleteClaim(_, { claimID }) {
        try {
            const data = await deleteReq(`app/safety-claim`, claimID);
            return data
        } catch (error) {
            if (error.response.status === 403) {
                throw "You are not allowed to perform this action.";
            }
            console.log(error.response);
            throw error.response;
        }
    }
}
const mutations = {
    setDotInspectionData: (state, dotInspectionData) => (state.dotInspectionData = dotInspectionData),
    setCurrentDotInspection: (state, dotInspection) => (state.currentDotInspection = dotInspection),
    setCurrentAccidentData: (state, accidentData) => (state.currentAccidentData = accidentData),
    setCurrentClaimData:(state, claimData)=>(state.currentClaimData=claimData)
}

export default {
    state,
    getters,
    actions,
    mutations,
};