<template>
  <b-container fluid class="mt-2 license-management-page">
    <b-row>
      <b-col cols="" class="px-1 px-sm-3">
        <div class="expiry-data d-flex justify-content-between align-items-center mb-4">
          <h2 class="text-left">Company</h2>
        </div>
        <div class="pr-0 mb-3 d-block d-lg-flex w-100">
          <div class="">
            <div class="pt-3 px-3 bg-white d-none d-lg-block table-custom-border rounded"
              :class="expiryWarning ? 'company-menu-section-with-banner' : 'company-menu-section'">
              <div class="company-tabs text-left">
                <div class="company-tab" @click="activeTab = 'general'" :class="activeTab === 'general' ? 'active' : ''">
                  General
                </div>
                <div class="company-tab" @click="activeTab = 'documents'"
                  :class="activeTab === 'documents' ? 'active' : ''" v-if="showDocumentsSection">
                  Documents
                </div>
                <div class="company-tab" @click="activeTab = 'contacts'" :class="activeTab === 'contacts' ? 'active' : ''"
                  v-if="showContactSection">
                  Contacts
                </div>
                <div class="company-tab" @click="activeTab = 'tags'" :class="activeTab === 'tags' ? 'active' : ''"
                  v-if="showTagsSection">
                  Tags
                </div>
                <div class="company-tab" @click="activeTab = 'docTypes'" :class="activeTab === 'docTypes' ? 'active' : ''"
                  v-if="showDocTypeSection">
                  Doc Types
                </div>
                <div class="company-tab" @click="activeTab = 'divisions'"
                  :class="activeTab === 'divisions' ? 'active' : ''" v-if="showDivisionSection">
                  Divisions
                  </div>
                  <div class="company-tab" @click="activeTab = 'settings'"
                    :class="activeTab === 'settings' ? 'active' : ''" v-if="showSettingsAndIntegrationSections">
                    Settings
                </div>
                <div class="company-tab" @click="activeTab = 'integrations'"
                  :class="activeTab === 'integrations' ? 'active' : ''" v-if="showSettingsAndIntegrationSections">
                  Integrations
                </div>
                <div class="company-tab" @click="activeTab = 'users'" :class="activeTab === 'users' ? 'active' : ''"
                  v-if="showUsersSection">
                  Users
                </div>
                <div class="company-tab" @click="activeTab = 'roles'" :class="activeTab === 'roles' ? 'active' : ''"
                  v-if="showRolesSection">
                  Roles
                </div>
                <div class="company-tab" @click="activeTab = 'activityLogs'" v-if="showActivityLogsSection"
                  :class="activeTab === 'activityLogs' ? 'active' : ''">
                  Activity Log
                </div>
              </div>
            </div>
            <div class="d-block d-lg-none mb-3">
              <b-form-select class="bg-white" v-model="activeTab" :options="dropDownoptions" />
            </div>
          </div>
          <div class="ml-lg-1 ml-0 table-custom-border rounded"
            :class="expiryWarning ? 'company-details-with-banner' : 'company-details'">
            <div class="">
              <General v-if="activeTab === 'general'" />
              <Documents v-if="activeTab === 'documents'" />
              <Settings v-if="activeTab === 'settings'" />
              <TagsSection v-if="activeTab === 'tags'" />
              <Users v-if="activeTab === 'users'" />
              <Integrations v-if="activeTab === 'integrations'" :company="company[0]"
                :showInnerSamsaraPage="showInnerSamsaraPage" @samsaraStatus="setSamsaraStatus($event)"
                @motiveStatus="setMotiveStatus($event)" :showInnerMotivePage="showInnerMotivePage"
                :showInnerPsPage="showInnerPsPage" :showInnerGeoTabPage="showInnerGeoTabPage"
                @geoTabStatus="setGeoTabStatus($event)" @isaacStatus="setIsaacStatus" @psStatus="setPsStatus"
                :showInnerIsaacPage="showInnerIsaacPage" />
              <DocTypes v-if="activeTab === 'docTypes'" />
              <ActivityLogs v-if="activeTab === 'activityLogs'" />
              <Contacts v-if="activeTab === 'contacts'" />
              <Divisions v-if="activeTab === 'divisions'" />
              <Roles v-if="activeTab === 'roles'" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Users from "@/components/Company/Users.vue"
import General from "@/components/Company/General.vue"
import Documents from "@/components/Company/Documents.vue"
import Integrations from "@/components/Company/Integrations";
import Settings from "@/components/Company/Settings.vue";
import TagsSection from "@/components/Company/TagsSection.vue";
import DocTypes from "@/components/Company/DocTypes.vue";
import ActivityLogs from "@/components/Company/ActivityLogs.vue";
import Contacts from "@/components/Company/Contacts.vue";
import Divisions from "@/components/Company/Divisions.vue";
import Roles from "@/components/Company/Roles";

export default {
  name: "Company",
  components: { Users, General, Integrations, Documents, Settings, TagsSection, DocTypes, ActivityLogs, Contacts, Divisions, Roles },
  data() {
    return {
      activeTab: "general",
      showInnerSamsaraPage: false,
      showInnerMotivePage: false,
      showInnerGeoTabPage: false,
      showInnerIsaacPage: false,
      showInnerPsPage: false,
      expiryWarning: null
    }

  },
  methods: {
    ...mapActions(['getCompany', "getAssetsData", "sendSamsaraCode", "sendMotiveCode",]),
    setSamsaraStatus(data) {
      this.showInnerSamsaraPage = data;
    },
    setMotiveStatus(data) {
      this.showInnerMotivePage = data;
    },
    setGeoTabStatus(data) {
      this.showInnerGeoTabPage = data
    },
    setIsaacStatus(data) {
      this.showInnerIsaacPage = data
    },
    setPsStatus(data) {
      this.showInnerPsPage = data
    },
    async setIntegrationsData() {
      this.showInnerSamsaraPage = false;
      this.showInnerMotivePage = false;
      await this.getAssetsData();
    },
  },
  computed: {
    ...mapGetters(["company", "rolePermissions"]),
    showUsersSection() {
      return this.rolePermissions && this.rolePermissions['Admin'] && this.rolePermissions['Admin'][0].allow
    },
    showRolesSection() {
      return this.rolePermissions && this.rolePermissions['Admin'] && this.rolePermissions['Admin'][1].allow
    },
    showDocumentsSection() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][0].allow
    },
    showSettingsAndIntegrationSections() {
      return this.rolePermissions && this.rolePermissions['Company Settings / Integrations'] && this.rolePermissions['Company Settings / Integrations'][0].allow
    },
    showContactSection() {
      return this.rolePermissions && this.rolePermissions['Contacts'] && this.rolePermissions['Contacts'][0].allow
    },
    showDivisionSection() {
      return this.rolePermissions && this.rolePermissions['Division'] && this.rolePermissions['Division'][0].allow
    },
    showDocTypeSection() {
      return this.rolePermissions && this.rolePermissions['Doc Types'] && this.rolePermissions['Doc Types'][0].allow
    },
    showTagsSection() {
      return this.rolePermissions && this.rolePermissions['Tags'] && this.rolePermissions['Tags'][0].allow
    },
    showActivityLogsSection() {
      return this.rolePermissions && this.rolePermissions['Activity Log'] && this.rolePermissions['Activity Log'][0].allow
    },
    dropDownoptions() {
      const options = [
        {
          value: 'general',
          text: 'General',
        }
      ];
      if (this.showDocumentsSection) {
        options.push({
          value: "documents",
          text: "Documents",
        });
      }
      if (this.showContactSection) {
        options.push({
          value: "contacts",
          text: "Contacts",
        });
      }
      if (this.showTagsSection) {
        options.push({
          value: "tags",
          text: "Tags",
        });
      }
      if (this.showDocTypeSection) {
        options.push({
          value: "docTypes",
          text: "Doc Types",
        });

      }
      if (this.showDivisionSection) {
        options.push({
          value: "divisions",
          text: "Divisions",
        });
      }
      if (this.showSettingsAndIntegrationSections) {
        options.push({
          value: "settings",
          text: "Settings",
        })
      }
      if (this.showSettingsAndIntegrationSections) {
        options.push({
          value: "integrations",
          text: "Integrations",
        })
      }
      if (this.showUsersSection) {
        options.push({
          value: "users",
          text: "Users",
        });
      }
      if (this.showRolesSection) {
        options.push({
          value: "roles",
          text: "Roles",
        });
      }


      if (this.showActivityLogsSection) {
        options.push({
          value: "activityLogs",
          text: "Activity Log",
        })
      }
      return options;
    }
  },
  destroyed() {
    document.querySelector("body").classList.remove("overflow-lg-hidden")
  },
  async mounted() {
    document.querySelector("body").classList.add("overflow-lg-hidden")
    if (
      this.$route.query.integration &&
      this.$route.query.integration === "samsara"
    ) {
      this.showInnerSamsaraPage = true;
      this.activeTab = 'integrations'
      if (this.$route.query.code)
        await this.sendSamsaraCode({ code: this.$route.query.code });
      await this.getCompany();
      await this.getAssetsData();
    }
    else if (
      this.$route.path.includes("motive")
    ) {
      this.showInnerMotivePage = true;
      this.activeTab = 'integrations'
      if (this.$route.query.code)
        await this.sendMotiveCode({ code: this.$route.query.code });
      await this.getCompany();
      await this.getAssetsData();
    }
    else await this.getCompany();
    const currentDate = new Date();
    const expiryDate = new Date(this.company[0].license_expiry_dates);
    const diffDays = parseInt(
      (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
      10
    );
    if (diffDays > 0 && diffDays <= 15) {
      this.expiryWarning = this.company[0].license_expiry_dates
    }
  }
}
</script>
<style>
.company-tab {
  color: #A9A9A9;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-left: 1.25rem;
}

.company-tab.active,
.company-tab:hover {
  color: #2D69F6;
  background: #F5F5F5;
  height: 40px;
  border-radius: 0.25rem;
}

.company-tab.active {
  border-left: 5px solid #2D69F6;
  padding-left: 1rem !important;
}

.modal-body .bi-x.cross_icon {
  position: absolute;
  right: 24px !important;
  top: 41px !important;
}

.card {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
  box-shadow: unset !important;
}

.card-header {
  border-bottom: none !important;
  background-color: transparent !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
}

/* .license-management-page .card-header{
    background: transparent !important;
} */
.tab-content {
  border-radius: 0px 5px 5px 5px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.nav-tabs .nav-link {
  background: var(--btn-bg-color) !important;
  color: #fff !important;
  margin-right: 20px;
  border-radius: 5px 5px 0px 0px;
  border: none !important;
  margin-bottom: 0px !important;
  font-size: 16px;
  font-weight: 600;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
  margin-top: 16px;
}

.nav-tabs .nav-item:last-child a {
  margin-right: 0;
}

.nav-tabs .nav-item a {
  padding: 0 52px;
}

#__BVID__16___BV_tab_button__ {
  width: 186px !important;
}

#__BVID__42___BV_tab_button__ {
  width: 256px !important;
}

.nav-item {
  overflow: hidden;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: #fff !important;
  color: var(--black) !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #fff;
}

.license {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.company_detail .heading {
  font-size: var(--small);
  font-weight: 600;
  width: 160px;
  text-align: left;
}

.company_name .title {
  text-transform: uppercase;
  font-size: var(--small);
}

.billing .mail {
  font-size: var(--small);
  line-height: 25px;
}

.billing .mail .edit {
  background-color: var(--btn-bg-color);
  border-radius: 5px;
  font-size: var(--extrasmall);
  color: #fff;
  padding: 1px 15px;
  cursor: pointer;
}

.billing input.form-control {
  width: 247px;
}

.license_inventory .bg_light {
  background-color: #f1f3ff;
  padding: 3rem 3rem;
}

.license_inventory h4 {
  font-size: 18px;
  font-weight: 600;
}

.license_inventory .detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px;
}

.license_inventory .detail .heading {
  text-transform: capitalize;
  font-size: var(--small);
  font-weight: 600;
}

.license_inventory .detail .title {
  font-size: var(--small);
}

.table {
  overflow-x: scroll;
}

.table h4 {
  font-size: 18px;
  font-weight: 600;
}

.table .add {
  background-color: var(--btn-bg-color);
}

.table .add:hover,
.table .add:active,
.table .add:focus {
  background-color: var(--btn-bg-color);
}

.table .add .b-icon {
  padding: 0 !important;
  margin-right: 10px;
}

.table .add .b-icon :not(svg) {
  color: #fff !important;
}

.buttons button {
  width: 100px;
  height: 40px;
}

.buttons .cancel {
  background-color: #F4F4F4 !important;
  color: var(--black);
  border: 1px solid #EAEAEA;
  width: 100px;
  height: 40px;
}

.buttons .save {
  background-color: var(--btn-bg-color) !important;
}

.buttons .save:hover,
.buttons .save:active,
.buttons .save:focus {
  background-color: var(--btn-bg-color) !important;
}

.card-header-tabs {
  margin-top: -0.5rem;
  margin-left: -0.5rem !important;
}

.company form .company_form,
.modal-select {
  position: relative;
}

.modal-select svg {
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.license-management-page .show-entries {
  position: relative;
}

.license-management-page .show-entries svg {
  position: absolute;
  right: 80px;
  bottom: 14px;
}

.company-table-action-btn {
  justify-content: end;
}

.company-details {
  max-height: calc(100vh - 150px);
  width: 100%;
  overflow: auto;
  height: fit-content;
}

.company-details-with-banner {
  max-height: calc(100vh - 210px);
  width: 100%;
  overflow: auto;
  height: fit-content;
}

.position-lg-fixed {
  position: fixed
}

@media (max-width: 991px) {
  .position-lg-fixed {
    position: relative;
  }

  .company-table-action-btn {
    justify-content: start;
  }

  .company-details,
  .company-details-with-banner {
    margin-bottom: 0rem;
    height: 100%;
    width: 100%;
  }

  .company .upload-btn-wrapper {
    width: 100% !important;
  }

  .license_inventory .bg_light {
    padding: 2rem 2rem;
  }

  .license_inventory.d-flex {
    margin-top: 30px;
    justify-content: center !important;
  }

  .company_detail .heading {
    width: 140px;
  }

  .license_inventory h4 {
    font-size: 16px;
  }

  .b-table {
    width: 780px !important;
  }
}

@media (max-width: 767px) {
  .company_detail {
    justify-content: center;
  }

  .nav-tabs .nav-item a {
    padding: 0px 18px;
  }

  #__BVID__16___BV_tab_button__,
  #__BVID__42___BV_tab_button__ {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    font-size: 14px;
  }

  .billing input.form-control {
    width: auto;
  }

  .buttons .cancel {
    margin-right: 0px !important;
  }
}

@media (max-width: 575px) {

  #__BVID__16___BV_tab_button__,
  #__BVID__42___BV_tab_button__ {
    padding-left: 10px;
    padding-right: 10px;
    width: auto !important;
  }

  .nav-tabs .nav-link {
    font-size: 14px;
  }

  form .company_form label {
    width: auto !important;
    max-width: auto;
  }

  input.form-control,
  .custom-select {
    font-size: 12px !important;
  }

  .company form .select_arrow {
    display: block !important;
    text-align: left;
    position: relative;
  }
}

@media (max-width: 470px) {

  .company_name,
  .billing {
    flex-wrap: wrap;
  }

  .license_inventory .bg_light {
    padding: 2rem 1rem;
  }

  .tab-pane {
    padding: 1rem 0rem !important;
  }
}

/* Company Tabs*/
.company .b-icon :not(svg) {
  color: var(--black);
}

/* .company form {
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
} */

.company form .b-icon {
  right: 20px;
  top: 13px;
}

.company form .company_form {
  display: flex;
  align-items: center;
}

.img_custom {
  position: relative !important;
  width: 100% !important;
  top: auto !important;
  left: auto !important;
  transform: none !important;
  height: 100% !important;
}

form .company_form label {
  max-width: 220px;
  width: 220px;
  text-align: left;
  font-weight: 600;
  color: var(--black);
}

.company .upload-btn-wrapper {
  margin-top: 0;
  height: 257px;
  width: 93%;
}

.company .upload-btn-wrapper img {
  top: 46%;
  /* width: 100%; */
  /* width: -webkit-fill-available; */
}

.company .upload-btn-wrapper .foo {
  padding: 0;
  height: 285px;
  width: 140px;
  /* position: relative;
  top: 215px; */
  background: transparent;
}

.company .upload-btn-wrapper input[type="file"] {
  width: 100%;
}

.company .upload {
  background-color: var(--btn-bg-color);
  width: 105px;
}

.company .upload:hover,
.company .upload:focus,
.company .upload:active {
  background-color: var(--btn-bg-color);
}

.upload_btn {
  position: relative;
  width: fit-content;
  margin: auto;
}

.upload_btn input {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: -webkit-fill-available;
}

.company-menu-section,
.company-menu-section-with-banner {
  width: 100%
}

@media (min-width: 992px) {
  .company-menu-section {
    width: 200px;
    height: calc(100vh - 150px);
    overflow-y: auto;
  }

  .company-menu-section-with-banner {
    width: 200px;
    height: calc(100vh - 210px);
    overflow-y: auto;
  }
}
</style>