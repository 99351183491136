<template>
    <b-modal id="send-invite-modal" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
        :body-class="'equipment-edit-modal'" no-close-on-esc>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                {{ title }}
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="body">
            <b-row>
                <b-col cols="12" class="mt-3">
                    <b-form-group label="Enter Password" class="text-left mb-0 password show-password-section">
                        <b-form-input v-model="password" placeholder="Enter Password" class="unit"
                            :type="showPassword ? 'text' : 'password'" required></b-form-input>

                        <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                            @click="showPassword = !showPassword"></b-icon>
                        <p v-if="errors && errors.password" class="field-error mb-lg-3">
                            {{ errors.password[0] }}
                        </p>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <template #modal-footer>
            <div class="text-right modal-footer border-0 p-0 mx-2">
                <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetForm()" variant="secondary">Cancel</b-button>
                <div class="d-flex align-items m-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendInvite()">Invite</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
export default {
    name: "SendInvite",
    props: {
        title: {
            type: String,
            default: "Send Invite"
        }
    },
    data() {
        return {
            showPassword: false,
            password: "",
            errors: {

            }
        }
    },
    methods: {
        resetForm() {
            this.password = "",
                this.errors = {
                }
            this.$bvModal.hide('send-invite-modal')
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        sendInvite() {
            try {
                const nullKeys = this.checkNullValues({
                    password: this.password
                })
                if (nullKeys.length === 0) {
                    this.$emit("sendInvite", this.password)
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        }
    },
}
</script>