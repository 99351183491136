var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{staticClass:"maintenance_modal",attrs:{"id":"modal-violation","centered":"","footer-class":'d-block px-3 pt-0 pb-3 m-0',"scrollable":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.violationData ? 'Edit' : 'Add')+" Violation ")]),_c('b-button',{staticClass:"d-none",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" Close Modal ")])]}},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"text-right modal-footer border-0 p-0"},[_c('b-button',{staticClass:"mt-3 mr-3 btn cancel m-0",attrs:{"block":""},on:{"click":function($event){return _vm.resetPopup()}}},[_vm._v("Close")]),_c('div',{staticClass:"d-flex align-items mt-3 m-0"},[(_vm.allowUpdateRecord)?_c('b-button',{staticClass:"btn save mx-0 w-100",attrs:{"variant":"primary"},on:{"click":_vm.onAddViolation}},[_vm._v("Save")]):_vm._e()],1)],1)]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{staticClass:"state"},[_vm._v("Code:")]),_c('b-form-input',{staticClass:"f-12",attrs:{"type":"text","disabled":!_vm.allowUpdateRecord,"placeholder":"Enter Code"},model:{value:(_vm.violationPayload.code),callback:function ($$v) {_vm.$set(_vm.violationPayload, "code", $$v)},expression:"violationPayload.code"}})],1),_c('b-form-group',[_c('label',{staticClass:"state"},[_vm._v("Description:")]),_c('b-form-textarea',{staticClass:"f-12",attrs:{"type":"text","disabled":!_vm.allowUpdateRecord,"rows":"6","placeholder":"Enter Description"},model:{value:(_vm.violationPayload.description),callback:function ($$v) {_vm.$set(_vm.violationPayload, "description", $$v)},expression:"violationPayload.description"}}),(_vm.errors && _vm.errors.description)?_c('p',{staticClass:"field-error"},[_vm._v(" "+_vm._s(_vm.errors.description[0])+" ")]):_vm._e()],1)],1),_c('b-col',{staticClass:"text-left",attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{staticClass:"state"},[_vm._v("Unit:")]),_c('b-form-select',{staticClass:"f-12",attrs:{"type":"text","disabled":!_vm.allowUpdateRecord,"options":[
                            {
                                value: '',
                                text: '',
                            },
                            {
                                value: 'Driver',
                                text: 'Driver',
                            },
                            {
                                value: 'Vehicle',
                                text: 'Vehicle',
                            },
                            {
                                value: 'Trailer',
                                text: 'Trailer',
                            }
                        ]},model:{value:(_vm.violationPayload.unit),callback:function ($$v) {_vm.$set(_vm.violationPayload, "unit", $$v)},expression:"violationPayload.unit"}}),(_vm.errors && _vm.errors.unit)?_c('p',{staticClass:"field-error"},[_vm._v(" "+_vm._s(_vm.errors.unit[0])+" ")]):_vm._e()],1),_c('b-form-group',[_c('label',{staticClass:"state"},[_vm._v("Weight:")]),_c('b-form-select',{staticClass:"f-12",attrs:{"type":"text","disabled":!_vm.allowUpdateRecord,"options":[
                            {
                                value: null,
                                text: '',
                            },
                            {
                                value: 1,
                                text: '1',
                            },
                            {
                                value: 2,
                                text: '2',
                            },
                            {
                                value: 3,
                                text: '3',
                            }
                        ]},model:{value:(_vm.violationPayload.weight),callback:function ($$v) {_vm.$set(_vm.violationPayload, "weight", $$v)},expression:"violationPayload.weight"}})],1),_c('b-form-group',[_c('label',{staticClass:"state"},[_vm._v("Points:")]),_c('b-form-input',{staticClass:"f-12",attrs:{"type":"text","disabled":!_vm.allowUpdateRecord,"placeholder":"Enter Points"},model:{value:(_vm.violationPayload.points),callback:function ($$v) {_vm.$set(_vm.violationPayload, "points", $$v)},expression:"violationPayload.points"}})],1)],1),_c('b-col',{staticClass:"text-left"},[_c('b-form-group',[_c('label',{staticClass:"state"},[_vm._v("OOS:")]),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg"},model:{value:(_vm.violationPayload.oos_status),callback:function ($$v) {_vm.$set(_vm.violationPayload, "oos_status", $$v)},expression:"violationPayload.oos_status"}})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }