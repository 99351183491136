import * as XLSX from 'xlsx/xlsx.mjs';
export function checkEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
export function areAllDigitsSame(number) {
  const digits = String(number).split("").map(Number);
  const firstDigit = digits[0];

  for (let i = 1; i < digits.length; i++) {
    if (digits[i] !== firstDigit) {
      return false;
    }
  }

  return true;
}
export function exportToExcel(data, fileName) {
  const workbook = XLSX.utils.book_new();

  // Convert the array of objects to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Write the workbook to a file
  XLSX.writeFile(workbook, fileName + '.xlsx');
}

export function checkValueExists(value){
  if(value) return value
  else return null
}