<template>
    <div class="integration-box">
        <div class="d-flex align-items-center flex-wrap">
            <img :src="require('@/assets/isaac.svg')" />
            <button v-if="status" type="button"
                class="h-100 btn btn-primary export equipment ml-0 ml-sm-4 mt-2 mt-sm-0 w-100px h-40px">
                Active
            </button>
            <button v-else type="button" class="h-40px btn btn-secondary close-btn ml-4 mt-2 mt-sm-0">
                Inactive
            </button>
        </div>
        <p class="mt-3 text-tertiary">
            Isaac integration will integrate your drivers, vehicles and trailers to our platform.
        </p>
        <div v-if="status">
            <div class="d-flex align-items-center flex-wrap mb-3" v-for="(item, index) in buttonsData" :key="index">
                <div>
                    <p class="mb-0">{{ item.title }}</p>
                    <button @click="setSyncData(item.link)" type="button" :disabled="item.status === true ? false : true"
                        class="h-auto btn btn-primary export equipment mt-2 w-100px text-white">
                        Sync Data
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between buttons-section flex-wrap">
            <div>
                <b-button type="button" v-if="status" class="w-125px h-40px" variant="danger" @click="removeToken()">
                    Deactivate
                </b-button>
                <b-button v-else class="border-0 assigned-equipment w-100px h-40px" variant="success"
                    @click="$bvModal.show('modal-isaac-activate')">Activate</b-button>
            </div>
            <button type="button" class="btn btn-secondary close-btn w-100px h-40px" @click="$emit('close')">
                Close
            </button>
        </div>
        <b-modal id="modal-isaac-activate" :header-bg-variant="'white'" header-class="border-none" no-close-on-backdrop
            :footer-class="'d-block px-3 pt-0 pb-3 m-0'" no-close-on-esc>
            <template #modal-header="{ close }">
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-center">
                <img :src="require('@/assets/isaac.svg')" />
            </div>
            <div class="mt-3">
                <b-form-group label="API URL" class="text-left">
                    <b-form-input type="text" class="input" v-model="form.isaac_path"></b-form-input>
                    <p v-if="errors && errors.isaac_path" class="field-error">
                        {{ errors.isaac_path[0] }}
                    </p>
                </b-form-group>
                <b-form-group label="Access Code" class="text-left">
                    <b-form-input type="text" class="input" v-model="form.isaac_auth_code"></b-form-input>
                    <p v-if="errors && errors.isaac_auth_code" class="field-error">
                        {{ errors.isaac_auth_code[0] }}
                    </p>
                </b-form-group>
                <b-form-group label="Username" class="text-left">
                    <b-form-input type="text" class="input" v-model="form.isaac_username"></b-form-input>
                    <p v-if="errors && errors.isaac_username" class="field-error">
                        {{ errors.isaac_username[0] }}
                    </p>
                </b-form-group>
                <b-form-group label="Password" class="text-left password show-password-section">
                    <b-form-input :type="showPassword ? 'text' : 'password'" class="input"
                        v-model="form.isaac_password"></b-form-input>
                    <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                        @click="showPassword = !showPassword"></b-icon>
                    <p v-if="errors && errors.isaac_password" class="field-error">
                        {{ errors.isaac_password[0] }}
                    </p>
                </b-form-group>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mr-3 btn cancel m-0" block @click="closePopup">Cancel</b-button>
                    <b-button class="btn save mx-0 m-0" variant="primary" @click="saveIsaacData">Save</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Isaac-Integration",
    props: ["status"],
    data() {
        return {
            form: {
                isaac_path: "",
                isaac_auth_code: "",
                isaac_username: "",
                isaac_password: ""
            },
            errors: {},
            showPassword: false,
            buttonsData: [
                {
                    title: "Vehicles",
                    link: "sync_isaac_vehicles",
                    status: true,
                    data: "Vehicle",
                },
                {
                    title: "Trailers",
                    link: "sync_isaac_trailers",
                    status: true,
                    data: "Trailer",
                },
                {
                    title: "Drivers",
                    link: "sync_isaac_drivers",
                    status: true,
                    data: "Driver",
                },
            ],
        };
    },
    methods: {
        ...mapActions([
            "setLoader",
            "setNotification",
            "setAssetsData",
            "setIsaacAccessToken",
            "getAssetsData",
            "updateCompany"
        ]),
        closePopup() {
            this.$bvModal.hide('modal-isaac-activate')
            this.errors = {}
            this.showPassword = false
            this.form = {
                isaac_path: "",
                isaac_auth_code: "",
                isaac_username: "",
                isaac_password: ""
            }
        },
        async setSyncData(link) {
            try {
                this.setLoader(true);
                await this.setAssetsData(link);
                await this.setNotification({
                    msg: "Sync done successfully !!",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async removeToken() {
            try {
                delete this.company[0].logo
                await this.updateCompany({
                    id: this.userCompany,
                    payload: { ...this.company[0], isaac_path: '', isaac_auth_code: '', isaac_username: '', isaac_password: '', state: this.company[0].state.id },
                });
                await this.setNotification({
                    msg: "Isaac Integration removed successfully !!",
                    type: "success",
                    color: "green",
                });
            }
            catch (e) {
                console.log(e)
            }
        },
        async saveIsaacData() {
            try {
                this.errors = {}
                const nullKeys = this.checkNullValues({
                    isaac_path: this.form.isaac_path,
                    isaac_auth_code: this.form.isaac_auth_code,
                    isaac_username: this.form.isaac_username,
                    isaac_password: this.form.isaac_password,
                });
                if (nullKeys.length === 0) {
                    delete this.company[0].logo
                    await this.setIsaacAccessToken({
                        path: this.form.isaac_path, auth_code: this.form.isaac_auth_code, username: this.form.isaac_username, password: this.form.isaac_password,
                    })
                    await this.updateCompany({
                        id: this.userCompany,
                        payload: { ...this.company[0], isaac_path: this.form.isaac_path, isaac_auth_code: this.form.isaac_auth_code, isaac_username: this.form.isaac_username, isaac_password: this.form.isaac_password, state: this.company[0].state.id },
                    });
                    await this.setNotification({
                        msg: "Isaac Integration done successfully !!",
                        type: "success",
                        color: "green",
                    });
                    this.closePopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    if (Object.prototype.hasOwnProperty.call(error, "error")) {
                        await this.setNotification({
                            msg: error.error,
                            type: "error",
                            color: "red",
                        });
                    }
                    else this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
    },
    computed: {
        ...mapGetters(["assetsData", "company", "userCompany"]),
    },
    mounted() { },
};
</script> 