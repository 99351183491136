<template>
    <b-modal id="modal-reject-doc" :header-bg-variant="'white'" no-close-on-backdrop
        :footer-class="'d-block px-3 pt-0 pb-3 m-0'" no-close-on-esc>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                Reject
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="d-block text-center">
            <b-form-group label="Reason:" class="text-left">
                <b-form-input type="text" class="input" v-model="form.reason"></b-form-input>
                <p v-if="errors && errors.reason" class="field-error">
                    {{ errors.reason[0] }}
                </p>
            </b-form-group>
        </div>
        <template #modal-footer>
            <div class="text-right modal-footer border-0 p-0 mr-2">
                <b-button class="mr-3 btn cancel m-0" block @click="resetPopup">Cancel</b-button>
                <b-button class="btn w-100px h-40px mx-0 m-0" variant="danger" @click="rejectDoc">Reject</b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import { mapActions } from 'vuex';
import * as moment from "moment-timezone";
export default {
    name: "RejectDoc",
    props:["documentItem","documentData"],
    data() {
        return {
            errors: {},
            form: {
                reason: ""
            }
        }
    },
    methods: {
        ...mapActions(['updateTeamMemberDocument', 'setLoader', 'setNotification']),
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async rejectDoc() {
            try {
                const nullKeys = this.checkNullValues({
                    reason: this.form.reason
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateTeamMemberDocument({
                        id: this.documentItem.pk,
                        payload: { ...this.documentItem, object_id: this.documentData.name ? this.documentData.name : this.documentItem.object_id, rejected: true, rejection_reason: this.form.reason, date: moment(this.documentItem.date).format("YYYY-MM-DD"), expiry_date: this.documentItem.expiry ? moment(this.documentItem.expiry).format("YYYY-MM-DD") : null },
                    });
                    await this.setNotification({
                        msg: "Document Successfully rejected",
                        type: "success",
                        color: "green",
                    });
                    this.resetPopup()
                    this.setLoader(false)
                    this.$emit('docRejected')
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        },
        resetPopup() {
            this.$bvModal.hide('modal-reject-doc')
            this.errors = {}
            this.form = {
                reason: ""
            }
        },
    },
}
</script>