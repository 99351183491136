import axios from "axios";
import router from "../router";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const setHeaders = () => {
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  if (localStorage.getItem("token")) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${localStorage.getItem("token")}`;
  }
  if(localStorage.getItem("userSelectedCompany")){
    axios.defaults.headers.common[
      "Company-Id"
    ] = localStorage.getItem("userSelectedCompany");
  }
};

export const getReq = async (enpoint) => {
  setHeaders();
  return await axios
    .get(`${enpoint}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      throw error;
    });
};

export const getById = async (enpoint, id) => {
  setHeaders();
  return await axios
    .get(`${enpoint}${id}/`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      throw error;
    });
};

export const postReq = async (enpoint, payload, key = null) => {
  setHeaders();
  if (key) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${key}`;
  }
  return await axios
    .post(`${enpoint}`, payload instanceof FormData ? payload : { ...payload, ...localStorage.getItem("userSelectedCompany") ? { company_id: parseInt(localStorage.getItem("userSelectedCompany")) } : {} })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      throw error;
    });
};


export const putReq = async (enpoint, id, payload) => {
  setHeaders();
  return await axios
    .put(`${enpoint}/${id}`, payload instanceof FormData ? payload : { ...payload, ...localStorage.getItem("userSelectedCompany") ? { company_id: parseInt(localStorage.getItem("userSelectedCompany")) } : {} })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      throw error;
    });
};
export const patchReq = async (enpoint, id, payload, key = null, addCompany= true) => {
  setHeaders();
  if (key) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${key}`;
  }
  return await axios
    .patch(`${enpoint}/${id}/${addCompany ? `?company_id=${key ? id : parseInt(localStorage.getItem("userSelectedCompany"))}`: ''}`, payload instanceof FormData ? payload : { ...payload, ...localStorage.getItem("userSelectedCompany") ? { company_id: parseInt(localStorage.getItem("userSelectedCompany")) } : {} })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      throw error;
    });
};

export const postFormData = async (enpoint, formData) => {
  setHeaders();
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  // axios.defaults.headers.post["Content-Type"] = "'application/x-www-form-urlencoded; charset=UTF-8'";
  return await axios
    .post(`${enpoint}`, formData)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      throw error;
    });
};

export const deleteReq = async (enpoint, id) => {
  setHeaders();
  return await axios
    .delete(`${enpoint}/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        localStorage.clear()
        delete axios.defaults.headers.common["Authorization"];
        router.push('/login')
      }
      console.log(error);
      throw error;
    });
};
