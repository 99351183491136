<template>
    <div>
        <b-modal id="modal-account-expiry" class="maintenance_modal" centered hide-footer :header-bg-variant="'white'"
            header-class="border-none" no-close-on-esc no-close-on-backdrop>
            <template #modal-header="{ close }">
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <h3 class="f-18 text-center mb-5 font-weight-bold">Your subscription expired ! Would you like to contact support ?</h3>
                <b-button variant="primary" class="mb-3 schedule-btn" @click="showSupportPopup()">Yes</b-button> <br />
                <b-button variant="secondary" class="mb-3 schedule-btn"
                    @click="logout()">Logout</b-button>
            </div>
        </b-modal>
        <Support :expiry="true"/>
    </div>
</template>
<script>
import Support from '../Help/Support.vue';
import { mapActions } from 'vuex';
export default{
    name:"Account-Expiry",
    components:{
        Support
    },
    methods: {
        ...mapActions(['logoutUser']),
        showSupportPopup(){
            this.$bvModal.hide('modal-account-expiry')
            this.$bvModal.show('support-modal')
        },
        logout() {
            this.logoutUser();
            this.$intercom.shutdown()
            this.$router.push("/login");
        },
    },
}
</script>